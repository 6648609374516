body {
  /* background-color: #020b19; */
  /* color: #a6aeb9; */
  /* theme 6  */
  /* background-color: #2c3439; */
  /* background-color: #2c3950; */
  /* color: #ced9bf; */
  /* background-color: #fdf5e8; */
  background-color: #1c1c1c;
;
  margin: 0 auto;
  max-width: 38rem;
  margin-top: 30px;
  /* text-transform: lowercase; */
}

a {
  color: #FFFFFF;
}
/* default */
.theme-0 {
  background-color: #020b19;
  color: #a6aeb9;
}
/**/
/* .theme-new { */
/*   background-color: #fdf5e8; */
/*   color: #0071a9; */
/* } */

.theme-new {
  background-color: #1c1c1c;
  color: #A9A9A9;
}


/* blue + green */
.theme-1 {
  background-color: #667985;
  color: #ced9bf;
}

h2 {
  color: #FFFFFF;
}

h4 {
  color: #FFFFFF;
}

/* blue + brown */
.theme-2 {
  background-color: #32424b;
  color: #c09576;
}

/* green + red */
.theme-3 {
  background-color: #c7d8c6;
  color: #ff5035;
}

/* red + pink */
.theme-4 {
  background-color: #f2c4b8;
  color: #1b227d;
}

/* green + yellow */
.theme-5 {
  background-color: #1d7948;
  color: #ddea90;
}

/* blue + green */
.theme-6 {
  background-color: #2c3439;
  color: #ced9bf;
}

/* green + brown */
.theme-7 {
  background-color: #dbd6cb;
  color: #171717;
}

/* purple + pink */
.theme-8 {
  background-color: #614f89;
  color: #fea4b9;
}

/* blue + cream */
.theme-9 {
  background-color: #2c3950;
  color: #f7d5b1;
}

/* orange + black */
.theme-10 {
  background-color: #e86e1a;
  color: #0b0e16;
}

ul {
  list-style-type: none;
}

h3 {
  color: #d6dae4;
}



#canvas {
  width: inherit;
  height: auto;
  margin-top: -250px;
  overflow: hidden;
  border-radius: 25px;
}

.App {
  padding: 2em;
  font-size: calc(10px + 0.75vmin);
}

.Avatar {
  width: auto;
  height: 140px;
  border-radius: 15px;
}

.header h1 {
  transition: color 0.5s ease;
}
