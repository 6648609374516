@font-face {
  font-family: "Berkeley Mono";
  src: url("./assets/BerkeleyMono-Regular.woff2") format("woff2"),
       url("./assets/BerkeleyMono-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Berkeley Mono";
  src: url("./assets/BerkeleyMono-Bold.woff2") format("woff2"),
       url("./assets/BerkeleyMono-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

body {
  line-height: 24px;
  margin: 0;
    font-family: 'Berkeley Mono', 'Spline Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  font-family: 'Berkeley Mono', 'Londrina Solid', serif;
}

.emoji {
  font-size: 24px;
}

.header h3 {
  font-size: 30px;
  /* font-weight: 700; */
  font-weight: bold;
}

.header p {
 font-size: calc(10px + .95vmin);
}